<template>
  <div class="home">
    <van-search v-model="searchValue" right-icon left-icon="" @search="onSearch" placeholder="输入症状、疾病、医院、科室、医生名">
      <div slot="right-icon" class="search_img" @click="onCheckLoginState('onSearch')">
        <img src="../assets/img/seach_icon.png" alt="" />
      </div>
    </van-search>
    <div class="fast_visits" @click="onCheckLoginState('toFastDoc')">
      <div class="left">
        <p>快速问诊</p>
        <p>3分钟内对症分配医生</p>
      </div>
      <img src="../assets/img/icon_kswz.png" alt="" />
    </div>
    <div class="fast_visits find_doc" @click="onCheckLoginState('toFindDoc')">
      <div class="left">
        <p>找医生</p>
        <p>
          <span>搜索全国名医 对症咨询</span>
          <span>支持图文提问 电话问诊</span>
        </p>
      </div>
      <img src="../assets/img/icon_zys.png" alt="" />
    </div>
    <div class="department">
      <div class="header">
        <p>热门科室</p>
        <p @click="onCheckLoginState('toFindDoc')">
          <span>全部</span>
          <img src="../assets/img/back.png" alt="" />
        </p>
      </div>
      <div class="department_type">
        <div @click="onCheckLoginState('clientFindDoc', 1, '妇科')">
          <img src="../assets/img/icon_fk.png" alt="" />
          <p>妇科</p>
        </div>
        <div @click="onCheckLoginState('clientFindDoc', 2, '儿科')">
          <img src="../assets/img/icon_ek.png" alt="" />
          <p>儿科</p>
        </div>
        <div @click="onCheckLoginState('clientFindDoc', 3, '内科')">
          <img src="../assets/img/icon_nk.png" alt="" />
          <p>内科</p>
        </div>
        <div @click="onCheckLoginState('clientFindDoc', 22, '报告解读科')">
          <img src="../assets/img/icon_bgjdk.png" alt="" />
          <p>报告解读科</p>
        </div>
      </div>
    </div>
    <div class="disease">
      <p class="title">常见疾病</p>
      <div class="disease_type">
        <div class="item" @click="onCheckLoginState('diseaseFindDoc', '咳嗽发热')">
          <p>咳嗽发热</p>
          <img class="icon1" src="../assets/img/img_ksfr.png" alt="" />
        </div>
        <div class="item" @click="onCheckLoginState('diseaseFindDoc', '便秘腹泻')">
          <p>便秘腹泻</p>
          <img class="icon2" src="../assets/img/img_bmfx.png" alt="" />
        </div>
      </div>
      <div class="disease_type">
        <div class="item" @click="onCheckLoginState('diseaseFindDoc', '胃痛胃胀')">
          <p>胃痛胃胀</p>
          <img class="icon3" src="../assets/img/img_wtwz.png" alt="" />
        </div>
        <div class="item" @click="onCheckLoginState('diseaseFindDoc', '皮肤过敏')">
          <p>皮肤过敏</p>
          <img class="icon4" src="../assets/img/img_pfgm.png" alt="" />
        </div>
      </div>
    </div>
    <img v-show="show" @click="closeDialog" src="../assets/img/close@2x.png" class="close" alt="" />
    <!-- 活动弹框 -->
    <van-swipe v-if="show" ref="swipe" class="my-swipe" indicator-color="white">
      <van-swipe-item v-for="item in currentList" :key="item.activityId" @click="goToYiYuan(item)">
        <div class="active">
          <!-- <img src="../assets/img/box@2x.png" /> -->
          <img v-lazy="item.mainPic" />
          <!-- <span>{{ item.activityName }}</span> -->
          <!-- <span>一元问诊</span> -->
          <!-- <span>医生为您的健康指导</span> -->
          <!-- <button @click="goToYiYuan(item)">立即问诊</button> -->
        </div>
      </van-swipe-item>
    </van-swipe>
    <div v-show="show" class="cover"></div>
    <v-login :is_login="is_login" @onLoginSuccess="onLoginSuccess" :canClose="true" @onLoginClose="onLoginClose" />
  </div>
</template>
<script>
import vLogin from "@/components/login";
import { getQueryVariable } from "../utils/util";
import BASE_URL from "@/request/base";
import Vue from "vue";
import { Lazyload } from "vant";
Vue.use(Lazyload);
export default {
  components: {
    vLogin,
  },
  data() {
    return {
      searchValue: "",
      show: false,
      is_login: false,
      loginState: null, // true=登录 false未登录
      currentList: [],
    };
  },
  created() {
    let loginData = JSON.parse(localStorage.getItem("userData")) || "";
    // console.log(loginData, "loginData");
    if (loginData) {
      // console.log("getList====>");
      this.is_login = false;
      this.loginState = true;
      this.heightPrice();
      this.getList();
      // this.show = true;
    } else {
      this.loginState = false;
    }
    this.$store.state.message = "";
    this.$store.state.arrImg = null;
    // this.getList()
  },
  // mounted() {
  //   var wxUrl = `${BASE_URL.wxUrl}`;
  //   // const wxUrl = location.href;
  //   // let wxUrl = 'https://test-onlinedoctor-h5.yaojixin.com'
  //   // console.log("sdfs", navigator.userAgent);
  //   // console.log("页面路径===>", wxUrl);
  //   let openId = localStorage.getItem("openid") || "";
  //   // console.log("openId===>", openId);
  //   // return false; // =================================================================================================
  //   if (!openId) {
  //     if (
  //       navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
  //       "micromessenger"
  //     ) {
  //       // console.log("微信内置");
  //       // console.log(wxUrl.indexOf("code"));
  //       if (wxUrl.indexOf("code") > -1) {
  //         let code = getQueryVariable("code");
  //         this.$http(
  //           "get",
  //           "/server/pay/getOfficialAccountOpenid?code=" + code,
  //           {},
  //           1
  //         ).then((res) => {
  //           // console.log(res);
  //           if (res.data.code == 200) {
  //             this.openid = res.data.data;
  //             localStorage.setItem("openid", this.openid);
  //           }
  //         });
  //       } else {
  //         const appid = "wx41c11ad329b19f43";
  //         let http =
  //           "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
  //           appid +
  //           "&redirect_uri=" +
  //           wxUrl +
  //           "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
  //         // console.log(http);
  //         location.href = http;
  //         // let a = document.createElement("a")
  //         // a.href = http;
  //         // a.click();
  //       }
  //     } else {
  //       // var currentURL = self.location.href;
  //       // if (currentURL.indexOf("?") <= 0) {
  //       //   currentURL += "?ran=" + Math.random();
  //       // } else {
  //       //   let index = currentURL.indexOf('?');
  //       //   currentURL = currentURL.substring(0, index)
  //       //   currentURL += "?ran=" + Math.random();
  //       // }
  //       // self.location.href = currentURL;
  //     }
  //   }
  // },

  mounted() {
    if (navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger") {
      wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {        //在小程序
        } else {
          // return             //在公众号
          var wxUrl = `${location.href}`;
          let openId = localStorage.getItem("openid") || "";
          if (!openId) {
            if (navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger") {
              if (wxUrl.indexOf("code") > -1) {
                let code = getQueryVariable("code");
                this.$http("get", "/server/pay/getOfficialAccountOpenid?code=" + code, {}, 1).then((res) => {
                  if (res.data.code == 200) {
                    this.openid = res.data.data;
                    localStorage.setItem("openid", this.openid);
                  }
                });
              } else {
                const appid = "wx41c11ad329b19f43";
                let http =
                  "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
                  appid +
                  "&redirect_uri=" +
                  wxUrl +
                  "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
                location.href = http;
              }
            }
          }
        }
      })
    }
  },
  methods: {
    onCheckLoginState(fnName, params1, params2) {
      if (this.loginState) {
        this[fnName](params1, params2);
      } else {
        this.is_login = true;
      }
    },
    // 获取当前活动列表数据
    getList() {
      this.$http("post", "/server/activityInfo/current", {}, 1).then((res) => {
        // // console.log(res, 'res======>');
        this.currentList = res.data.data;
        if (this.currentList.length) {
          this.show = true
        }
      });
    },
    // 前往一元问诊
    goToYiYuan(item) {
      this.$store.state.visitsType = 4;
      this.$store.commit('setActiveId', item.activityId)
      this.$router.push({
        // path: "/special_one_yuan",
        path: "/" + item.activityUrl,
        query: { id: item.activityId },
      });
    },
    // 关闭弹框
    closeDialog() {
      this.show = false;
    },
    heightPrice() {
      this.$store.dispatch("setPrice");
    },
    // 快速问诊
    toFastDoc() {
      this.$store.state.visitsType = 3;
      this.$router.push({ path: "/choose_patients" });
    },
    toFindDoc() {
      this.$router.push("/find_doc");
    },
    onClick() {
      this.$router.push("/detail");
    },
    // 搜索
    onSearch() {
      let data = {
        query_text: this.searchValue,
        type: 1,
      };
      this.$router.push({ path: "/doctor_list", query: data });
    },
    // 根据科室找医生
    clientFindDoc(clinic_no, text) {
      let data = {
        clinic_no: clinic_no,
        type: 2,
        query_text: text,
      };
      this.$router.push({ path: "/doctor_list", query: data });
    },
    // 常见疾病找医生
    diseaseFindDoc(text) {
      let data = {
        query_text: text,
        type: 1,
      };
      this.$router.push({ path: "/doctor_list", query: data });
    },
    // 登录成功
    onLoginSuccess() {
      this.is_login = false;
      this.loginState = true;
      // this.show = true;
      this.getList();
    },
    onLoginClose() {
      this.is_login = false;
    },
  },
};
</script>
<style lang="less" scoped>
.cover {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1a1a1a;
  opacity: 0.49;
  z-index: 1000;
}
.my-swipe {
  width: 5.08rem;
  height: 6.74rem;
  position: absolute;
  bottom: 4.22rem;
  left: 1.2rem;
  z-index: 9999;
  .active {
    // background: url('../assets/img/box@2x.png') ;
    background-size: 100% 100%;
    // width: 5.08rem;
    // height: 6.74rem;
    text-align: center;
    box-sizing: border-box;
    // padding-top: 0.36rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      // width: 5.08rem;
      // height: 6.74rem;
    }
    span {
      display: block;
    }
    span:nth-child(2) {
      line-height: 0.6rem;
      letter-spacing: 5px;
      text-shadow: 0px 0px 0px #0063db;
      font-size: 0.5rem;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 0.1rem;
      position: absolute;
      top: 0.36rem;
      right: 1.28rem;
    }
    span:nth-child(3) {
      text-shadow: 0px 0px 0px #2e97f2;
      font-size: 0.34rem;
      font-weight: normal;
      position: absolute;
      top: 1.04rem;
      right: 0.64rem;
      color: #ffffff;
      line-height: 0.48rem;
      letter-spacing: 3px;
    }
    button {
      width: 4.06rem;
      height: 0.7rem;
      background: linear-gradient(180deg, #8affe7 0%, #28c5d2 100%);
      border-radius: 0.35rem;
      color: #ffffff;
      line-height: 0.44rem;
      text-shadow: 0px 0px 0px #00b2b0;
      border: none;
      position: absolute;
      bottom: 0.78rem;
      right: 0.52rem;
    }
  }
}
.close {
  // background-color: sandybrown;
  width: 0.7rem;
  height: 0.7rem;
  position: absolute;
  bottom: 3.22rem;
  left: 3.4rem;
  z-index: 9999;
}
.home {
  padding: 0 0.24rem 1.86rem;
  width: calc(100% - 0.48rem);
  // overflow: auto;
  /deep/ .van-search {
    padding-left: 0;
    padding-right: 0;
  }
  .search_img {
    width: 0.36rem;
    height: 0.38rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .fast_visits {
    background-image: url("../assets/img/bg_kswz.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    display: flex;
    height: 2.16rem;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 0.98rem 0rem 0.82rem;
    margin-top: 0.2rem;
    .left {
      // margin-right: 1.3rem;
      p:nth-child(1) {
        color: #fff;
        font-size: 0.36rem;
        font-weight: 500;
        text-shadow: 0px 2px 34px #f6f6f6;
        margin-bottom: 0.14rem;
      }
      p:nth-child(2) {
        display: flex;
        flex-direction: column;
        font-size: 0.26rem;
        color: #fff;
        font-weight: 400;
        text-shadow: 0px 2px 34px #f6f6f6;
      }
    }
    img {
      width: 1.2rem;
      height: 1.02rem;
    }
  }
  .find_doc {
    background-image: url("../assets/img/bg_zys.png");
    img {
      width: 1.02rem;
      height: 1.06rem;
    }
  }
  .department {
    margin-top: 0.4rem;
    width: 100%;
    .header {
      display: flex;
      justify-content: space-between;
      p:nth-child(1) {
        color: #333333;
        font-size: 0.3rem;
        font-weight: 600;
      }
      p:nth-child(2) {
        display: flex;
        align-items: center;
        span {
          color: #666;
          font-size: 0.28rem;
          margin-right: 0.12rem;
        }
        img {
          width: 0.14rem;
          height: 0.24rem;
        }
      }
    }
    .department_type {
      padding: 0 0.2rem;
      width: calc(100% - 0.4rem);
      margin-top: 0.3rem;
      display: flex;
      justify-content: space-between;
      text-align: center;
      img {
        width: 1.02rem;
        height: 1.02rem;
        margin-bottom: 0.2rem;
      }
      p {
        color: #333333;
        font-size: 0.28rem;
        text-align: center;
      }
    }
  }
  .disease {
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    .title {
      color: #333333;
      font-size: 0.3rem;
      font-weight: 600;
    }
    .disease_type {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 0.24rem;
      // margin-bottom: 0
      .item {
        width: 3.4rem;
        height: 1.22rem;
        background: #ecf6ff;
        line-height: 1.22rem;
        border-radius: 3px;
        display: flex;
        align-items: flex-end;
        p {
          margin-left: 0.64rem;
          color: #333333;
          font-size: 0.28rem;
          // margin-right: 0.46rem;
        }
        .icon1 {
          width: 0.98rem;
          height: 0.98rem;
          margin-left: 0.5rem;
        }
        .icon2 {
          width: 0.68rem;
          height: 0.88rem;
          margin-left: 0.74rem;
        }
        .icon3 {
          width: 0.76rem;
          height: 1.04rem;
          margin-left: 0.74rem;
        }
        .icon4 {
          width: 0.86rem;
          height: 0.84rem;
          margin-left: 0.6rem;
        }
      }
    }
  }
}
</style>
